.text-pending {
    color: #F36A25
}

.text-error {
    color: #DA2C38
}

.text-success {
    color: #004039
}
