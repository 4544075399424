.sr-root {
  --primary: var(--primary-color);
}

.sr-radio-box:active{
  background: rgba(0, 64, 57, 0.05);
  border: 1px solid #004039;
}

.sr-panel__content{
  padding: 0!important;
}

.sd-item--disabled.sd-item--disabled .sd-item__decorator, .sd-item__decorator{
  width: calc(3 * var(--base-unit, 0.4rem))!important;
  height: calc(3 * var(--base-unit, 0.4rem))!important;
}

.sd-radio--checked .sd-radio__decorator:after{
  width: calc(1 * var(--base-unit, 0.3rem))!important;
  height: calc(1 * var(--base-unit, 0.3rem))!important;
}

.sr-element__title{
  margin-right: 40px;
}

.sr-element__title:after{
  content: "";
  display: inline-block;
  height: calc(2 * var(--base-unit, 0.8rem));
  width: calc(2 * var(--base-unit, 0.8rem));
  position: absolute;
  right: calc(1 * var(--base-unit, 0.5rem));
  top: calc(0.5 * var(--base-unit, 0.5rem));
}

.sr-element__title--expanded:after{
  background-image: url("../../../../shared/ui/src/lib/assets/images/chevron--up.svg");
}

.sr-element__title--collapsed:after{
  background-image: url("../../../../shared/ui/src/lib/assets/images/chevron--down.svg");
}

.sd-panel .sd-row{
  margin-top: calc(2 * var(--base-unit, 0.8rem))
}

.sd-element--nested.sd-panel, .sd-element--nested.sd-question--paneldynamic{
  border: none;
}

.sd-row__question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.sd-panel__content[style*="padding-left: 20px;"]:nth-child(-n+1){
  background: rgba(0, 64, 57, 0.02);
  padding: 25px;
}

.sv-tagbox__item{
  height: auto;
}
