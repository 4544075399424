
.rbc-time-view {
  border: none;
}

.rbc-time-content {
  border: none;
}

.rbc-header {
  padding-bottom: 20px;
}

.rbc-header + .rbc-header {
  border-left: none;
}

.rbc-time-header .rbc-time-header-content {
  border: none;
}


.rbc-day-bg {
  background: white;
}

.rbc-time-gutter > .rbc-timeslot-group {
  border: none;
}

.rbc-time-content > * + * > * {
  border-left:none;
}

.rbc-day-slot .rbc-time-slot {
  border-top: 0;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0;
}

.rbc-day-slot .rbc-time-slot {
  background-color: #F4F1DE;
  border-radius: 5px;
}

.rbc-today {
  background-color: white;
}

.rbc-allday-cell {
  display: none;
}

.rbc-label {
  font-size: 0.75rem;
}